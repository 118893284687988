body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}
html {
    height: -webkit-fill-available;
}

.doc-type .list-group-item {
    min-height: 65px;
    padding: .5rem 1.5rem;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.doc-type .list-group-item:hover:after {
    opacity: 1;
}

.doc-type .list-group-item.selected {
    font-weight: 500;
    background: rgb(0 79 97 / 4%);
    color: green;
    border: 1px solid green;
}

.doc-type .list-group-item:after {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    display: block;
    border: 1px solid green;
    border-radius: 1px;

    opacity: 0;
    transition: opacity .3s cubic-bezier(.4,0,.2,1);
    content: "";
    pointer-events: none;
}

.camera-component {
    position: relative;
    display: block;
    width: 560px;
    max-width: 100%;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    margin: 0 auto 16px;
    background: #34363d;
    border-radius: 10px;
}

.sgirz5a {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding: 56.25% 0 0;
}

.camera {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
}

.iw2dhaj {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

.s1vyr0as {
    position: absolute;
    right: 0;
    left: 0;
    display: none;
    width: 560px;
    height: 315px;
    margin: auto;
}

.sgirz5a {
    position: relative;
    display: grid;
    grid-row-gap: 8px;
    grid-template-rows: auto auto -webkit-min-content;
    grid-template-rows: auto auto min-content;
    grid-template-columns: 1fr;
    min-height: 350px;
    padding: 8px 0 0;
    text-align: center;
    border-radius: .25rem;
    overflow: hidden;
    background: grey;
}

.s31ynh2 {
    position: absolute;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}


.scvu48f {
    text-align: center;
    color: white;
    display: none
}


.b1c7psjy {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 7.5rem;
    height: 2.5rem;
    padding: 0 1rem;
    color: white;
    font-weight: 500;
    -webkit-letter-spacing: .4px;
    -moz-letter-spacing: .4px;
    -ms-letter-spacing: .4px;
    letter-spacing: .4px;
    text-transform: uppercase;
    background: white;
    border: none;
    border-radius: var(--size-border-radius);
    cursor: pointer;
    transition: all .3s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.sc7u7vd {
    display:none;
}

@media (max-width: 767px) {

    .desktop-button {
        display: none;
    }
    .sgirz5a {
        grid-row-gap: 0;
        grid-template-rows: 1fr auto;
        min-height: 100vh;
        min-height: -webkit-fill-available;
        padding: 0;
        top: 0;
        left: 0;
        position: absolute;
        border-radius: 0px;
        overflow: auto;
    }
    .v1ou7fdm {
        position: static;
        padding: 0;
    }
    .s31ynh2 {
        top: 26%;
        height: 22vh;
    }
    .vpym2a3 {
        border-radius: 0;
    }
    .s1vyr0as {
        display: block;
        width: 100%;
        height: 100%;
    }
        .s7e9nf9 {
            display: none;
        }
    .v116frs7 {
        position: static;
        order: 1;
        width: 100%;
        height: auto;
        margin: 0;
        border-radius: 0;
    }
    .scvu48f {
        position: absolute;
        right: 0;
        bottom: 88px;
        left: 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        max-height: 100px;
        margin: 0 auto;
    }

    .sc7u7vd {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        justify-content: center;
        justify-items: center;
        order: 4;
        margin-bottom: 16px;
        direction: ltr!important;
    }
    .s1o96zdj {
        display: inline-block;
        grid-column: 2;
        width: 64px;
        min-width: unset;
        height: 64px;
        margin: 0;
        color: white;
        background: white;
        font-size: 0;
        vertical-align: top;
        background: none!important;
        border: 4px solid #fff;
        border-radius: 50%;
        outline: none;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }
    .s1o96zdj:after {
        position: relative;
        left: 0;
        display: block;
        width: 48px;
        height: 48px;
        margin-left: -12px;
        background: #fff;
        border-radius: 50%;
        content: "";
    }


}

.f1ttuk76 {
    --fade-out-delay:100s;transition-timing-function: ease-in;
    -webkit-animation-name: fadeOut-f1ttuk76;
    animation-name: fadeOut-f1ttuk76;
    -webkit-animation-duration: 100s;
    animation-duration: 100s;
    -webkit-animation-delay: var(--fade-out-delay);
    animation-delay: var(--fade-out-delay);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

@-webkit-keyframes fadeOut-f1ttuk76 {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fadeOut-f1ttuk76 {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.f1i29ehj .front {
    position: absolute;
    z-index: 2;
    transform: rotateY(0deg);
    -webkit-animation: darken-f1i29ehj 2.5s 2 forwards,fade-f1i29ehj 2.5s 2 forwards;
    animation: darken-f1i29ehj 2.5s 2 forwards,fade-f1i29ehj 2.5s 2 forwards;
}
.f1i29ehj .back {
    position: relative;
    transform: rotateY(180deg);
    -webkit-animation: fade-f1i29ehj 2.5s 2 forwards,nothing-f1i29ehj 2.5s 2 forwards;
    animation: fade-f1i29ehj 2.5s 2 forwards,nothing-f1i29ehj 2.5s 2 forwards;
}
.f1i29ehj .back, .f1i29ehj .front {
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.f1i29ehj {
    height: 100%;
    margin: auto;
    transform-style: preserve-3d;
    -webkit-animation: flip-f1i29ehj 2.5s 2 forwards;
    animation: flip-f1i29ehj 2.5s 2 forwards;
}

.f1qv9pa6 {
    top: 78px;
    width: 50%;
    margin: 0 auto;
    -ms-perspective: 700px;
    perspective: 700px;
}
.f1qv9pa6, .iw2dhaj {
    position: absolute;
    right: 0;
    left: 0;
}



.iw2dhaj {
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center
}

.f1qv9pa6,.iw2dhaj {
    position: absolute;
    right: 0;
    left: 0
}

.f1qv9pa6 {
    top: 78px;
    width: 50%;
    margin: 0 auto;
    -ms-perspective: 700px;
    perspective: 700px
}

@media (min-width: 768px) {
    .f1qv9pa6.medium {
        top:84px;
        width: 45%
    }

    .f1qv9pa6.small {
        top: 92px;
        width: 40%
    }

}

@media (max-width: 767px) {
    .f1qv9pa6 {
        top:27.5%;
        right: 0;
        left: 0;
        display: block;
        width: auto;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        height: 19vh;
        margin-right: auto;
        margin-left: auto
    }

    .f1qv9pa6.medium {
        top: 26.8%
    }

    .f1qv9pa6.small {
        top: 25.8%
    }
}

.f1i29ehj {
    height: 100%;
    margin: auto;
    transform-style: preserve-3d;
    -webkit-animation: flip-f1i29ehj 2.5s 2 forwards;
    animation: flip-f1i29ehj 2.5s 2 forwards
}

@-webkit-keyframes flip-f1i29ehj {
    10% {
        transform: rotateY(0deg)
    }

    70% {
        transform: rotateY(180deg)
    }

    to {
        transform: rotateY(180deg)
    }
}

@keyframes flip-f1i29ehj {
    10% {
        transform: rotateY(0deg)
    }

    70% {
        transform: rotateY(180deg)
    }

    to {
        transform: rotateY(180deg)
    }
}

@-webkit-keyframes darken-f1i29ehj {
    10% {
        filter: brightness(1)
    }

    70% {
        filter: brightness(0)
    }

    to {
        filter: brightness(0)
    }
}

@keyframes darken-f1i29ehj {
    10% {
        filter: brightness(1)
    }

    70% {
        filter: brightness(0)
    }

    to {
        filter: brightness(0)
    }
}

@-webkit-keyframes fade-f1i29ehj {
    0% {
        opacity: 0
    }

    15% {
        opacity: 1
    }

    85% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fade-f1i29ehj {
    0% {
        opacity: 0
    }

    15% {
        opacity: 1
    }

    85% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-webkit-keyframes nothing-f1i29ehj {
    0% {
        top: 0
    }

    to {
        top: 0
    }
}

@keyframes nothing-f1i29ehj {
    0% {
        top: 0
    }

    to {
        top: 0
    }
}

.f1i29ehj .front {
    position: absolute;
    z-index: 2;
    transform: rotateY(0deg);
    -webkit-animation: darken-f1i29ehj 2.5s 2 forwards,fade-f1i29ehj 2.5s 2 forwards;
    animation: darken-f1i29ehj 2.5s 2 forwards,fade-f1i29ehj 2.5s 2 forwards
}

.f1i29ehj .back,.f1i29ehj .front {
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.f1i29ehj .back {
    position: relative;
    transform: rotateY(180deg);
    -webkit-animation: fade-f1i29ehj 2.5s 2 forwards,nothing-f1i29ehj 2.5s 2 forwards;
    animation: fade-f1i29ehj 2.5s 2 forwards,nothing-f1i29ehj 2.5s 2 forwards
}


@-webkit-keyframes fadeOut-f1ttuk76 {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fadeOut-f1ttuk76 {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

._loading_overlay_overlay {
    background: white;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

.dropzone {
    position: relative;

    min-height: 167px;
    text-align: center;
    overflow: hidden;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: grey;
    border-style: dashed;
    background-color: white;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.dropzone .f1qv9pa6 {
    top: 10px!important;
}
.dropzone .s31ynh2 {
    top: 0!important;
}

.dropzone .s1vyr0as {
    display: none!important;
}
